
.main-heading {
  font-family: 'Alex Brush', cursive;
  color: #B6C649;
  text-shadow: 1px 1px #696D7D;
  margin-left: 2rem;
  font-size: 2.5rem;
}
.main-heading-container {
  top: 0;
  left: 0;
  position: absolute;
}
.centered-content {
  color: #E6F4F3;
  font-family: 'League Spartan', sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  animation: fadeInText;
  animation-duration: 3s;
  animation-iteration-count: 1;

}
@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
}

.header {
  height: 100vh;
  position: relative;
  display: cover;
  color: black;
  background-image: url('assets/main-backdrop.jpg');
}

@media(max-width: 504px) {
  .centered-content {
    font-size: 1rem;
  }
}
@media(max-width: 376px){
  .centered-content {
    font-size: .7rem;
  }
}